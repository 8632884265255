import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/wcenatus/Documents/GitHub/cwa-site/src/templates/MdxLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Visa`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://usa.visa.com/"
      }}>{`www.usa.visa.com`}</a></p>
    <p><strong parentName="p">{`MasterCard`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://www.mastercard.us/en-us.html"
      }}>{`www.mastercard.com`}</a></p>
    <p><strong parentName="p">{`Discover`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://www.discover.com/"
      }}>{`www.discover.com`}</a></p>
    <p><strong parentName="p">{`American Express`}</strong></p>
    <p><a parentName="p" {...{
        "href": "http://www.americanexpress.com"
      }}>{`www.americanexpress.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      